import { css } from "glamor";
import React from "react";
import AnnotationListItemContainer from "../annotations/AnnotationListItemContainer";
import Link from "../library/Link";
import { colors, spacing } from "../library/theme";

const container = {
  maxWidth: "1080px",
  margin: "0 auto",
  paddingBottom: spacing.m,
};
const annotationsWrapper = css({
  margin: `${spacing.m} 0 ${spacing.l}`,
  display: "flex",
  gap: spacing.m,
  flexWrap: "wrap",
  justifyContent: "center",
  alignItems: "flex-start",
});
const titleClass = css({
  ":hover": {
    color: colors.primary,
  },
});

export default function CourseAnnotationsLesson(props) {
  const { lesson, annotations, course } = props;

  return annotations.length ? (
    <div style={container}>
      <Link to={`/courses/${course.id}/lessons/${lesson.id}`}>
        <h2 className={titleClass}>{lesson.name}</h2>
      </Link>
      <div className={annotationsWrapper}>
        {annotations.map((annotation, index) => (
          <AnnotationListItemContainer key={index} annotation={annotation} />
        ))}
      </div>
    </div>
  ) : null;
}
