import { css } from "glamor";
import React from "react";
import Button from "../library/Button";
import { colors, spacing, styles } from "../library/theme";

let bounce = css.keyframes({
  "0%": { transform: "scale(0.1)", opacity: 0 },
  "60%": { transform: "scale(1.2)", opacity: 1 },
  "100%": { transform: "scale(1)" },
});

const container = css(
  styles.roundedCorners,
  styles.transition,
  styles.cardShadow,
  {
    textAlign: "center",
    backgroundColor: colors.white,
    padding: spacing.l,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    animation: `${bounce} 0.2s`,
    position: "relative",
    minHeight: 260,
    minWidth: 346,
    width: 346,
  }
);
const textClass = css({
  color: colors.greyShadow,
  lineHeight: "1.5 !important",
});
const deleteStyle = {
  position: "absolute",
  top: spacing.s,
  right: spacing.xxs,
};

export default function AnnotationListItem({ annotation, deleteHandler }) {
  return (
    <div className={container}>
      <Button
        onClick={deleteHandler}
        confirmationText="Deseja apagar anotação?"
        popupText="Apagar anotação"
        icon="delete"
        size="mini"
        style={deleteStyle}
        circular
      />
      <p className={textClass}>{annotation.text}</p>
    </div>
  );
}
