import { apiGet, apiPost } from "./helpers/api";
import { normalize, schema } from "normalizr";
import { fetchMock } from "./mockProccess";

const answer = new schema.Entity("answers");

export const fetchAnswers = (dispatch, mockId) => {
  apiGet(`/api/v1/mocks/${mockId}/answers`).then((response) => {
    dispatch({
      type: "ANSWERS_FETCHED",
      ...normalize(response.body.data, new schema.Array(answer)),
    });
  });
};

export const createLessonAnswer = (dispatch, lesson, replyId) => {
  apiPost(`/api/v1/courses/${lesson.courseId}/lessons/${lesson?.id}/answers`)
    .send({
      answer: {
        courseId: lesson?.courseId,
        lessonId: lesson?.id,
        questionId: lesson?.meta?.question,
        replyId,
      },
    })
    .then((response) => {
      dispatch({
        type: "ANSWER_CREATED",
        ...normalize(response.body.data, answer),
      });
    });
};

export const createMockAnswer = (
  dispatch,
  courseId,
  mockId,
  questionId,
  replyId,
  skipMockFetch = false
) => {
  apiPost(`/api/v1/mocks/${mockId}/answers`)
    .send({ answer: { courseId, mockId, questionId, replyId } })
    .then((response) => {
      dispatch({
        type: "ANSWER_CREATED",
        ...normalize(response.body.data, answer),
      });
      /* skipMockFetch is needed until api/v1/mock_serializer.rb line 12
       (hotfix app not showing the results) is not fixed */
      !skipMockFetch && fetchMock(dispatch, mockId);
    });
};

export const fetchLessonAnswers = (dispatch, courseId, lessonId) => {
  dispatch({ type: "ANSWERS_FETCH_REQUESTED" });
  apiGet(`/api/v1/courses/${courseId}/lessons/${lessonId}/answers`).then(
    (response) =>
      dispatch({
        type: "ANSWERS_FETCHED",
        ...normalize(response.body.data, new schema.Array(answer)),
      })
  );
};
