import { createSelector } from "reselect";
import { getCurrentCourseId } from "./courseSelectors";
import { getLessonId } from "./lessonSelectors";

const getAnnotations = (state) => state.entities.annotations;
const getId = (_, id) => id;

export const getAllAnnotations = createSelector(getAnnotations, (annotations) =>
  Object.values(annotations)
);

const lastFirst = (a, b) => b.id - a.id;

export const getAnnotationsByLesson = createSelector(
  getAllAnnotations,
  getLessonId,
  getCurrentCourseId,
  (annotations, lessonId, courseId) =>
    annotations
      .filter(
        (annotation) =>
          annotation.lessonId === lessonId && annotation.courseId == courseId
      )
      .sort(lastFirst)
);

export const getCourseAnnotations = createSelector(
  getAllAnnotations,
  getId,
  (annotations, id) =>
    annotations.filter((annotation) => annotation.courseId == id)
);
