import React from "react";
import { css } from "glamor";
import AnnotationsFormContainer from "./AnnotationsFormContainer";
import AnnotationsListContainer from "./AnnotationsListContainer";

const container = css({
  display: "flex",
  flexDirection: "column",
  height: "100%",
});

export default function Annotations({ lessonId, annotations }) {
  return (
    <div className={container}>
      <AnnotationsFormContainer lessonId={lessonId} />
      <AnnotationsListContainer annotations={annotations} />
    </div>
  );
}
