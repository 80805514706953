import React, { useCallback, useState, useEffect } from "react";
import { connect } from "react-redux";
import buildForm from "../library/forms/buildForm";
import { searchCoupon } from "../../processes/couponProcesses";
import { couponFound } from "../../selectors/couponSelectors";
import SearchCouponForm from "./SearchCouponForm";
import { formValueSelector } from "redux-form";

const FORM_NAME = "searchCoupon";
const form = buildForm(FORM_NAME);

function SearchCouponFormContainer(props) {
  const [showCouponForm, setShowCouponForm] = useState(false);
  const openShowCouponForm = useCallback(() => {
    setShowCouponForm(true);
  }, []);

  return (
    <SearchCouponForm
      showCouponForm={showCouponForm}
      openShowCouponForm={openShowCouponForm}
      {...props}
    />
  );
}

function mapStateToProps(state) {
  const valueSelector = formValueSelector(FORM_NAME);
  const code = valueSelector(state, "code");

  return {
    initialValues: {},
    hasCoupon: couponFound(state, code),
  };
}

export default connect(mapStateToProps)(
  form.connect({
    onSubmit: searchCoupon,
  })(SearchCouponFormContainer)
);
