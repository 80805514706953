import { getItem } from "../utils/localStorage";

const initialState = {
  selectedReply: null,
  loadingAlternatives: true,
  loadingAnswer: true,
  playerOption: getItem("lessonPlayerOption") || "vimeo",
};

export default function lessonReducer(state = initialState, action) {
  switch (action.type) {
    case "ANSWERS_FETCH_REQUESTED":
      return {
        ...state,
        loadingAnswer: true,
        selectedReply: null,
      };
    case "ANSWERS_FETCHED":
      return {
        ...state,
        loadingAnswer: false,
      };
    case "REPLIES_FETCH_REQUESTED":
      return {
        ...state,
        loadingAlternatives: true,
        selectedReply: null,
      };
    case "REPLIES_FETCHED":
      return {
        ...state,
        loadingAlternatives: false,
      };
    case "REPLY_SELECTED":
      return {
        ...state,
        selectedReply: action.reply,
      };
    case "PLAYER_SELECTED":
      return {
        ...state,
        playerOption: action.option,
      };
    default:
      return state;
  }
}
