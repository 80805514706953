import React, { useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import { getCurrentUser } from "../../selectors/userSelectors";
import { fetchPublicConfigs } from "../../processes/configProcesses";
import {
  fetchCourseOffers,
  fetchCourseOffer,
} from "../../processes/courseOffersProcesses";
import CourseCheckout from "./CourseCheckout";
import { getCouponByCode } from "../../selectors/couponSelectors";
import { formValueSelector, hasSubmitSucceeded } from "redux-form";
import {
  getCourseOffersDefaultByCourseId,
  getCourseOfferById,
} from "../../selectors/courseOfferSelectors";
import { couponDiscount } from "../../utils/calcPaymentValue";
import { getPaymentCheckout } from "../../selectors/paymentCheckoutSelectors";

const COUPON_FORM_NAME = "searchCoupon";
const PAYMENT_FORM_NAME = "payment";

function CourseCheckoutContainer(props) {
  const {
    dispatch,
    courseId,
    courseOfferId,
    wasPreviousStudent,
    coupon,
    installmentCount,
    offer,
  } = props;

  const [loading, setLoading] = useState(true);
  const [discountConfirmed, setDiscountConfirmed] = useState(null);

  const confirmDiscountHandler = useCallback(() => {
    setDiscountConfirmed(true);
  }, []);

  useEffect(() => {
    fetchPublicConfigs(dispatch);
  }, [dispatch]);

  useEffect(() => {
    if (!courseOfferId) return;

    fetchCourseOffer(dispatch, courseOfferId).then(() => {
      setLoading(false);
    });
  }, [dispatch, courseOfferId]);

  useEffect(() => {
    const skipDiscountConfirmationValue =
      !wasPreviousStudent || !offer?.hasStudentDiscount;
    setDiscountConfirmed(skipDiscountConfirmationValue);
  }, [wasPreviousStudent, offer?.hasStudentDiscount]);

  useEffect(() => {
    dispatch({ type: "COUPONS_RESETED" });
  }, [dispatch, offer]);

  useEffect(() => {
    if (!courseId) return;

    fetchCourseOffers(dispatch, courseId).then(() => {
      setLoading(false);
    });
  }, [dispatch, courseId]);

  return (
    <CourseCheckout
      loading={loading}
      discountConfirmed={discountConfirmed}
      confirmDiscountHandler={confirmDiscountHandler}
      resellDiscountValue={
        wasPreviousStudent ? offer?.price - offer?.resellPrice : 0
      }
      {...props}
    />
  );
}

function mapStateToProps(state, ownProps) {
  const courseId = ownProps.match.params.courseId;
  const courseOfferId = ownProps.match.params.courseOfferId;

  const couponFormValueSelector = formValueSelector(COUPON_FORM_NAME);
  const searchedCoupon = couponFormValueSelector(state, "code");

  const coupon = getCouponByCode(state, searchedCoupon);
  const user = getCurrentUser(state);

  const paymentFormValueSelector = formValueSelector(PAYMENT_FORM_NAME);
  const { installmentCount, billingType } = paymentFormValueSelector(
    state,
    "installmentCount",
    "billingType"
  );

  const { leadCreated, leadIsFromPreparatoryCourse } =
    getPaymentCheckout(state);

  const courseOffer = getCourseOfferById(state, courseOfferId);
  const defaultCourseOffers = getCourseOffersDefaultByCourseId(state, courseId);

  return {
    courseOfferId,
    courseId: courseId || courseOffer?.courseId,
    user,
    coupon,
    offer: courseOffer || defaultCourseOffers[0],
    wasPreviousStudent:
      user?.isFromPreparatoryCourse || leadIsFromPreparatoryCourse,
    installmentCount,
    billingType,
    submitSucceeded: hasSubmitSucceeded(PAYMENT_FORM_NAME)(state),
    leadCreated,
  };
}

export default connect(mapStateToProps)(CourseCheckoutContainer);
