import React, { useCallback, useState } from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { createLessonRating } from "../../processes/ratingsProcesses";
import { getCurrentLessonId } from "../../selectors/lessonSelectors";
import { getIsWatchedByLessonId } from "../../selectors/lessonsUserSelectors";
import { canRateLesson } from "../../selectors/ratingSelectors";
import LessonRatingForm from "./LessonRatingForm";

function LessonRatingFormContainer(props) {
  const { submit } = props;
  const [openModal, setOpenModal] = useState(false);

  const toggleModal = useCallback(() => {
    setOpenModal(!openModal);
  }, [openModal]);

  const submitHandler = useCallback(() => {
    submit().then(() => {
      toggleModal();
    });
  }, [submit, toggleModal]);

  return (
    <LessonRatingForm
      openModal={openModal}
      toggleModal={toggleModal}
      submitHandler={submitHandler}
      {...props}
    />
  );
}

function mapStateToProps(state) {
  const currentLessonId = getCurrentLessonId(state);

  return {
    initialValues: {},
    currentLessonId: getCurrentLessonId(state),
    canRate: canRateLesson(state, currentLessonId),
    watched: getIsWatchedByLessonId(state, currentLessonId),
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    onSubmit: createLessonRating,
  })(LessonRatingFormContainer)
);
