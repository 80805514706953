import { createSelector } from "reselect";

const getCurrentUserId = (state) => state.currentUserId;
const getCurrentCourseId = (state) => state.currentCourseId;
const getLessons = (state) => state.entities.lessons;
const getLessonId = (_, id) => id;
const getCoursesUsers = (state) => state.entities.coursesUsers;
const getId = (_, id) => id;

export const getAllCoursesUsers = createSelector(
  getCoursesUsers,
  (coursesUsers) => Object.values(coursesUsers)
);

export const getAllCoursesUsersFromCurrentUser = createSelector(
  getAllCoursesUsers,
  getCurrentUserId,
  (coursesUsers, currentUserId) =>
    coursesUsers.filter(({ userId }) => userId == currentUserId)
);

export const getCourseUserByCourseId = createSelector(
  getAllCoursesUsersFromCurrentUser,
  getId,
  (coursesUsers, courseId) =>
    coursesUsers.filter((coursesUser) => coursesUser.courseId == courseId)[0]
);

export const getLessonById = createSelector(
  getLessons,
  getLessonId,
  (lessons, id) => lessons[id]
);

export const getCourseUserByLessonId = createSelector(
  getAllCoursesUsersFromCurrentUser,
  getLessonById,
  (coursesUsers, lesson) =>
    coursesUsers.filter(
      (coursesUser) => coursesUser.courseId == lesson?.courseId
    )[0]
);

export const getCurrentCourseUser = createSelector(
  getAllCoursesUsersFromCurrentUser,
  getCurrentCourseId,
  (coursesUsers, courseId) =>
    coursesUsers.filter((coursesUser) => coursesUser.courseId == courseId)[0]
);

export const getCurrentUserTrialCoursesUsers = createSelector(
  getAllCoursesUsersFromCurrentUser,
  (coursesUsers) => coursesUsers.filter(({ trial }) => trial)
);
