import { connect } from "react-redux";
import { getCourseCategoriesForList } from "../../selectors/configSelectors";
import { getAllBanners } from "../../selectors/bannerSelectors";
import { getCurrentUser } from "../../selectors/userSelectors";
import Courses from "./Courses";

function mapStateToProps(state) {
  const banners = getAllBanners(state);

  return {
    user: getCurrentUser(state),
    loading: state.coursesList.loading,
    hasBanners: !!banners.length,
    courseCategories: getCourseCategoriesForList(state),
  };
}

export default connect(mapStateToProps)(Courses);
