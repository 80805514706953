import { connectRouter } from "connected-react-router";
import { combineReducers } from "redux";
import { reducer as form } from "redux-form";
import annotationsReducer from "./annotationsReducer";
import answersReducer from "./answersReducer";
import bannersReducer from "./bannersReducer";
import certificateUserReducer from "./certificateUserReducer";
import commentListReducer from "./commentListReducer";
import commentsReducer from "./commentsReducer";
import configsReducer from "./configsReducer";
import contentFeedReducer from "./contentFeedReducer";
import contentReducer from "./contentReducer";
import contentsReducer from "./contentsReducer";
import coursesListReducer from "./coursesListReducer";
import coursesReducer from "./coursesReducer";
import currentCourseReducer from "./currentCourseReducer";
import currentLessonReducer from "./currentLessonReducer";
import currentUserReducer from "./currentUserReducer";
import discardRepliesReducer from "./discardRepliesReducer";
import lessonFilesReducer from "./lessonFilesReducer";
import lessonReducer from "./lessonReducer";
import lessonsReducer from "./lessonsReducer";
import lessonsUsersReducer from "./lessonsUsersReducer";
import levelGroupsReducer from "./levelGroupsReducer";
import levelsReducer from "./levelsReducer";
import likesReducer from "./likesReducer";
import mockListReducer from "./mockListReducer";
import mock from "./mockReducer";
import mocksReducer from "./mocksReducer";
import mockRankingsReducer from "./mockRankingsReducer";
import mockTemplatesReducer from "./mockTemplatesReducer";
import networking from "./networkingReducer";
import notificationsReducer from "./notificationsReducer";
import notificationsUsersReducer from "./notificationsUsersReducer";
import notifier from "./notifierReducer";
import promotionsReducer from "./promotionsReducer";
import questionListReducer from "./questionListReducer";
import questionsReducer from "./questionsReducer";
import ratingsReducer from "./ratingsReducer";
import repliesReducer from "./repliesReducer";
import report from "./reportReducer";
import surveysReducer from "./surveysReducer";
import surveysUsersReducer from "./surveysUsersReducer";
import surveyUtilsReducer from "./surveyUtilsReducer";
import termsReducer from "./termsReducer";
import trailsReducer from "./trailsReducer";
import usersReducer from "./usersReducer";
import userFilesReducer from "./userFilesReducer";
import mockPreferencesReducer from "./mockPreferencesReducer";
import couponsReducer from "./couponsReducer";
import creditCardTokensReducer from "./creditCardTokensReducer";
import courseOffersReducer from "./courseOffersReducer";
import courseFilesReducer from "./courseFilesReducer";
import afterSignInPathReducer from "./afterSignInPathReducer";
import paymentCheckoutReducer from "./paymentCheckoutReducer";
import paymentsReducer from "./paymentsReducer";
import certificatesReducer from "./certificatesReducer";
import viewsReducer from "./viewsReducer";
import viewListReducer from "./viewListReducer";
import classroomsReducer from "./classroomsReducer";
import coursesUsersReducer from "./coursesUsersReducer";
import invoicesListReducer from "./invoicesListReducer";
import companiesReducer from "./companiesReducer";

export default (history) =>
  combineReducers({
    certificateUser: certificateUserReducer,
    commentList: commentListReducer,
    content: contentReducer,
    contentFeed: contentFeedReducer,
    coursesList: coursesListReducer,
    currentCourseId: currentCourseReducer,
    currentLessonId: currentLessonReducer,
    currentUserId: currentUserReducer,
    discardReplies: discardRepliesReducer,
    entities: combineReducers({
      annotations: annotationsReducer,
      answers: answersReducer,
      banners: bannersReducer,
      comments: commentsReducer,
      courses: coursesReducer,
      contents: contentsReducer,
      configs: configsReducer,
      coupons: couponsReducer,
      creditCardTokens: creditCardTokensReducer,
      courseOffers: courseOffersReducer,
      courseFiles: courseFilesReducer,
      classrooms: classroomsReducer,
      coursesUsers: coursesUsersReducer,
      companies: companiesReducer,
      lessons: lessonsReducer,
      lessonsUsers: lessonsUsersReducer,
      levelGroups: levelGroupsReducer,
      levels: levelsReducer,
      lessonFiles: lessonFilesReducer,
      mocks: mocksReducer,
      mockRankings: mockRankingsReducer,
      notifications: notificationsReducer,
      notificationsUsers: notificationsUsersReducer,
      questions: questionsReducer,
      replies: repliesReducer,
      terms: termsReducer,
      promotions: promotionsReducer,
      users: usersReducer,
      userFiles: userFilesReducer,
      ratings: ratingsReducer,
      likes: likesReducer,
      trails: trailsReducer,
      surveys: surveysReducer,
      payments: paymentsReducer,
      surveysUsers: surveysUsersReducer,
      mockTemplates: mockTemplatesReducer,
      views: viewsReducer,
    }),
    form,
    paymentCheckout: paymentCheckoutReducer,
    lesson: lessonReducer,
    mock,
    mockList: mockListReducer,
    mockPreferences: mockPreferencesReducer,
    networking,
    notifier,
    questionList: questionListReducer,
    report,
    router: connectRouter(history),
    surveyUtils: surveyUtilsReducer,
    afterSignInPath: afterSignInPathReducer,
    certificates: certificatesReducer,
    viewList: viewListReducer,
    invoicesList: invoicesListReducer,
  });
