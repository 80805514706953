import { createSelector } from "reselect";

const getRatings = (state) => state.entities.ratings;
const getId = (_, id) => id;

const getAllRatings = createSelector(getRatings, (ratings) =>
  Object.values(ratings)
);

export const getLessonRating = createSelector(
  getAllRatings,
  getId,
  (ratings, ratingableId) =>
    ratings.filter(
      (rate) =>
        rate.ratingableType === "Lesson" && rate.ratingableId === ratingableId
    )[0]
);

export const canRateLesson = createSelector(
  getLessonRating,
  (rating) => !rating
);
