import { css } from "glamor";
import React from "react";
import BannersContainer from "../banners/BannersContainer";
import { colors, Page, spacing, Spinner, styles } from "../library";
import CourseList from "./CourseList";

const bannersWrapper = css({
  marginBottom: spacing.l,
});

const titleClass = css(styles.alternativeFontFamily, {
  color: colors.secondary,
  fontWeight: "bold",
  minHeight: 28,
  marginBottom: spacing.l,
});

const marginBottom = css({ marginBottom: spacing.l });

export default function Courses({ loading, courseCategories, hasBanners }) {
  return (
    <Page title="Meus Cursos">
      {hasBanners && (
        <div className={bannersWrapper}>
          <BannersContainer />
        </div>
      )}
      {loading ? (
        <Spinner wrapped />
      ) : (
        <>
          {courseCategories && (
            <CourseList courseCategories={courseCategories} justOwned />
          )}
        </>
      )}
    </Page>
  );
}
