import { css } from "glamor";
import React, { useCallback } from "react";
import { createTextMask } from "redux-form-input-masks";
import { Grid, Message } from "semantic-ui-react";
import { Button, Link, Icon, colors, spacing } from "../library";
import { DocumentField } from "../library/forms";
import NameField from "../library/forms/NameField";
import PhoneField from "../library/forms/PhoneField";
import CourseCheckoutPrivacyWarning from "../courseCheckout/CourseCheckoutPrivacyWarning";
import AddressFields from "./AddressFields";
import CourseCheckoutDetails from "../courseCheckout/CourseCheckoutDetails";
import { toBRLCurrency } from "../../utils/formatCurrency";

const container = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  background: colors.white,
  width: "100%",
  padding: spacing.l,
};
const titleWraper = css({
  marginBottom: spacing.l,
});
const titleClass = css({
  color: colors.black,
  fontWeight: "bold",
  lineHeight: 1.3,
  marginBottom: spacing.l,
});
const qrCodeStyle = {
  width: "200px",
  margin: `${spacing.s} auto ${spacing.m} auto`,
};
const afterPaymentWrapper = css({
  display: "flex",
  flexWrap: "wrap",
});
const titleStyle = {
  fontWeight: 700,
  letterSpacing: 0.8,
  marginBottom: spacing.m,
};
const textStyle = {
  textAlign: "center",
  padding: `0 ${spacing.m}`,
  lineHeight: 1.2,
  whiteSpace: "pre-line",
};
const buyButtonStyle = {
  marginTop: spacing.m,
};
const code = {
  padding: spacing.xs,
  background: colors.greyClear,
};
const orderedList = css({
  marginBottom: spacing.m,
  "> p": {
    margin: 0,
  },
});
const backStyleWrapper = {
  margin: `${spacing.m} 0`,
};
const backStyle = {
  textDecoration: "underline",
  cursor: "pointer",
};
const privacyWarningWrapper = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "end",
};
const backWrapper = {
  textAlign: "left",
  paddingTop: spacing.s,
};
const linkStyle = {
  cursor: "pointer",
};
const leftContent = css({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  minWidth: "350px",
  padding: spacing.l,
  "@media(max-width: 575px)": {
    padding: spacing.l,
  },
});
const copyWrapper = {
  display: "flex",
  alignItems: "center",
  gap: spacing.m,
  justifyContent: "space-between",
  background: colors.greyLighter,
  borderRadius: spacing.xs,
  padding: `${spacing.xs} ${spacing.s}`,
  marginBottom: spacing.m,
};
const copyButton = {
  color: "#009EDE",
};
const copyBigButton = {
  marginTop: spacing.l,
};
const codeWrapper = {
  flex: 1,
  lineHeight: 1.1,
  wordBreak: "break-word",
  whiteSpace: "nowrap",
  overflowX: "auto",
  overflowY: "hidden",
};
const fullWidth = {
  width: "100%",
};
const rightContent = css({
  flex: 1,
  minWidth: "350px",
  background: "#F0F1F6",
  display: "flex",
  flexDirection: "column",
  padding: spacing.l,
  "@media(max-width: 575px)": {
    padding: spacing.l,
  },
});
const detailsWrapper = css({
  marginTop: spacing.m,
  borderTop: `1px solid ${colors.greyLight}`,
  paddingTop: spacing.m,
});
const titleWrapper = {
  marginBottom: spacing.l,
};
const fieldsWrapper = css({
  marginBottom: spacing.m,
});
const postalCodeMask = createTextMask({
  pattern: "99999-999",
  guide: false,
  allowEmpty: false,
});

export default function PaymentMethotPix(props) {
  const {
    submitSucceeded,
    defaultGateway,
    externalContent,
    externalContentDetails,
    copyToClipboardHandler,
    street,
    neighborhood,
    cityId,
    stateId,
    stateIdField,
    addressLoading,
    states,
    cities,
    fetchCities,
    confirmDiscount,
    discountConfirmed,
    incompleteName,
    incompletePhone,
    user,
    resetBillingTypeHandler,
    handleSubmit,
    invalid,
    submitting,
    paymentValue,
    error,
    countryCodeOptions,
  } = props;

  const submitHandler = useCallback(() => {
    !discountConfirmed && confirmDiscount();
    handleSubmit();
  }, [handleSubmit, confirmDiscount, discountConfirmed]);

  return submitSucceeded ? (
    <div className={afterPaymentWrapper}>
      <div className={leftContent}>
        <div className={titleWrapper}>
          <h3 className={titleClass}>Pix</h3>
        </div>

        <div className={orderedList}>
          <p>1. Acesse seu app de pagamentos</p>
          <p>2. Selecione pagar via Pix</p>
          <p>3. Escaneie este QR Code</p>
        </div>

        <h4 style={titleStyle}>Ou copie esse código QR para pagar</h4>

        <div style={copyWrapper}>
          <div style={codeWrapper}>{externalContentDetails}</div>

          <Link onClick={copyToClipboardHandler} style={copyButton}>
            Copiar
          </Link>
        </div>

        <div style={fullWidth}>
          <p style={fullWidth}>
            <b>Recebedor:</b> T2 Educação S/A
          </p>
          <p style={fullWidth}>
            <b>Valor:</b> {toBRLCurrency(paymentValue)}
          </p>
        </div>

        <Button
          type="button"
          onClick={copyToClipboardHandler}
          content="Copiar Código"
          color="black"
          size="big"
          style={copyBigButton}
          fluid
        />
      </div>

      <div className={rightContent}>
        <h3 className={css(textStyle, titleClass)}>QR CODE</h3>
        <img src={externalContent} alt="PIX QR CODE" style={qrCodeStyle} />
        <h4 style={textStyle}>{`Pague e será
        creditado na hora`}</h4>
        <div className={detailsWrapper}>
          <CourseCheckoutDetails {...props} />
        </div>
      </div>
    </div>
  ) : (
    <div style={container}>
      <div className={titleWraper}>
        <h3 className={titleClass}>Pix</h3>
        {error && <Message content={error} error={error} />}
      </div>

      <div className={fieldsWrapper}>
        {incompleteName && (
          <NameField
            name="customerData[name]"
            placeholder="Nome Completo"
            fluid
            required
          />
        )}
        {incompletePhone && (
          <PhoneField
            name="customerData[phone]"
            countryCodeOptions={countryCodeOptions}
            countryCodeFieldName="customerData[phoneCountryCodeId]"
            required
            fluid
          />
        )}
        <DocumentField
          name="customerData[cpfCnpj]"
          placeholder="CPF / CNPJ"
          fluid
          required
        />
      </div>

      <AddressFields
        street={street}
        neighborhood={neighborhood}
        cityId={cityId}
        stateId={stateId}
        stateIdField={stateIdField}
        addressLoading={addressLoading}
        states={states}
        cities={cities}
        fetchCities={fetchCities}
      />

      <Button
        type={discountConfirmed ? "submit" : "button"}
        color="black"
        size="big"
        icon="shopping cart"
        content="GERAR QR CODE"
        fluid
        disabled={invalid || submitting}
        onClick={submitHandler}
        confirmationText={
          discountConfirmed
            ? undefined
            : "Você tem direito ao desconto de aluno da T2!"
        }
        confirmButton="Usar desconto"
        cancelButton="Cancelar"
        style={buyButtonStyle}
      />

      <div style={backWrapper}>
        <Link style={linkStyle} onClick={resetBillingTypeHandler}>
          <Icon name="angle left" /> Etapa Anterior
        </Link>
      </div>
      <div style={privacyWarningWrapper}>
        <CourseCheckoutPrivacyWarning />
      </div>
    </div>
  );
}
