import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import CourseAnnotations from "./CourseAnnotations";
import { fetchCourseAnnotations } from "../../processes/annotationProcesses";
import { orderedListOfLessons } from "../../selectors/lessonSelectors";
import { currentCourse } from "../../selectors/courseSelectors";
import { getCourseAnnotations } from "../../selectors/annotationSelectors";

function CourseAnnotationsContainer(props) {
  const { dispatch, course } = props;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    if (!course?.id) {
      return;
    }

    fetchCourseAnnotations(dispatch, course?.id)
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [dispatch, course?.id]);

  return <CourseAnnotations loading={loading} {...props} />;
}

function mapStateToProps(state) {
  const course = currentCourse(state);
  return {
    course,
    lessons: orderedListOfLessons(state),
    annotations: getCourseAnnotations(state, course?.id),
  };
}

export default connect(mapStateToProps)(CourseAnnotationsContainer);
