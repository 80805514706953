import { connect } from "react-redux";
import { getCourseCategoriesWithUserCourse } from "../../selectors/configSelectors";
import { getAllBanners } from "../../selectors/bannerSelectors";
import CoursesUser from "./CoursesUser";

function mapStateToProps(state) {
  const banners = getAllBanners(state);

  return {
    loading: state.coursesList.loading,
    hasBanners: !!banners.length,
    courseCategories: getCourseCategoriesWithUserCourse(state),
  };
}

export default connect(mapStateToProps)(CoursesUser);
