const initialState = {
  loading: true,
  courseIds: [],
  searchLessonIds: [],
  searchLessonLoading: false,
};

const getCoursesFetchedIds = (action) =>
  action.result ? action.result : initialState.courseIds;

export default function coursesListReducer(state = initialState, action) {
  switch (action.type) {
    case "COURSE_LESSON_SEARCH_FETCH_REQUEST":
      return { ...state, searchLessonLoading: true };
    case "COURSE_LESSON_SEARCH_FETCHED":
      return {
        ...state,
        searchLessonIds: action.result,
        searchLessonLoading: false,
      };
    case "COURSES_FETCH_REQUESTED":
      return { ...state, loading: true };
    case "COURSES_FETCHED":
      return {
        ...state,
        loading: false,
        courseIds: getCoursesFetchedIds(action),
      };
    default:
      return state;
  }
}
