import React from "react";
import { Card } from "semantic-ui-react";
import Button from "../library/Button";
import { Form, TextAreaField } from "../library/forms";
import { colors, spacing, styles } from "../library/theme";

const container = {
  ...styles.border,
  paddingTop: 0,
  marginBottom: 0,
  borderRadius: spacing.xs,
  border: "none",
  boxShadow: "none",
  textAlign: "right",
};
const buttonStyle = {
  marginTop: spacing.s,
  backgroundColor: colors.primary,
  color: colors.greyClear,
};

export default function SignInForm(props) {
  return (
    <Card style={container} fluid>
      <Card.Content>
        <Form onSubmit={props.handleSubmit}>
          <TextAreaField
            name="text"
            placeholder="Faça uma anotação..."
            maxLength={400}
            rows={2}
          />
          <Button size="small" style={buttonStyle}>
            CRIAR
          </Button>
        </Form>
      </Card.Content>
    </Card>
  );
}
