import React from "react";
import { Card } from "semantic-ui-react";
import { spacing } from "../library/theme";
import Button from "../library/Button";
import Form from "../library/forms/Form";
import TextField from "../library/forms/TextField";
import DropDownField from "../library/forms/DropDownField";
import makeDropDownOptions from "../../utils/makeDropDownOptions";
import { css } from "glamor";

const textFieldClass = css({
  width: "100%",
  backgroundColor: "white",
});

const submitButtonStyle = {
  marginTop: spacing.m,
};

export default function AddStudentForm({
  handleSubmit,
  company,
  selectedCourseId,
  submitting,
  pristine,
  ...rest
}) {
  console.log("AddStudentForm's rest", rest);
  return (
    <Form onSubmit={handleSubmit} style={{ width: "100%" }}>
      <TextField
        name="name"
        className={textFieldClass}
        label="Nome da turma"
        required
      />

      <DropDownField
        name="courseId"
        label="Curso"
        options={makeDropDownOptions(company?.meta?.courses || [])}
        required
      />

      <Button
        loading={submitting}
        disabled={submitting || pristine}
        type="submit"
        fluid
        color="green"
        style={submitButtonStyle}
      >
        CRIAR TURMA
      </Button>
    </Form>
  );
}
