const getAnswers = (action) => action.entities.answers;

export default function answersReducer(state = {}, action) {
  switch (action.type) {
    case "ANSWER_CREATED":
    case "ANSWERS_FETCHED":
      return { ...state, ...getAnswers(action) };
    default:
      return state;
  }
}
