import { css } from "glamor";
import React, { useCallback } from "react";
import { createTextMask } from "redux-form-input-masks";
import { Grid, Message } from "semantic-ui-react";
import { Button, colors, Link, Icon, spacing } from "../library";
import { DocumentField } from "../library/forms";
import NameField from "../library/forms/NameField";
import PhoneField from "../library/forms/PhoneField";
import CourseCheckoutPrivacyWarning from "../courseCheckout/CourseCheckoutPrivacyWarning";
import AddressFields from "./AddressFields";
import { toBRLCurrency } from "../../utils/formatCurrency";

const container = css({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  background: colors.white,
  width: "100%",
  padding: spacing.l,
});
const titleWrapper = {
  marginBottom: spacing.l,
};
const titleClass = css({
  color: colors.black,
  fontWeight: "bold",
  lineHeight: 1.3,
  marginBottom: spacing.l,
});
const buyButtonStyle = {
  marginTop: spacing.l,
};
const afterPaymentWrapper = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: spacing.l,
};
const titleStyle = {
  fontWeight: 700,
  letterSpacing: 0.8,
};
const titleWithMargin = {
  ...titleStyle,
  margin: `${spacing.m} 0 0 0`,
};
const textStyle = {
  textAlign: "center",
  lineHeight: 1.2,
  color: colors.black,
  whiteSpace: "break-spaces",
};
const copyWrapper = {
  display: "flex",
  alignItems: "center",
  gap: spacing.m,
  justifyContent: "space-between",
  background: colors.greyLighter,
  borderRadius: spacing.xs,
  padding: `${spacing.xs} ${spacing.s}`,
};
const copyButton = {
  color: "#009EDE",
};
const codeWrapper = {
  flex: 1,
  lineHeight: 1.1,
  wordBreak: "break-word",
};
const code = {
  padding: spacing.xs,
  background: colors.greyClear,
};
const backStyleWrapper = {
  margin: `${spacing.m} 0`,
};
const backStyle = {
  textDecoration: "underline",
  cursor: "pointer",
};
const backWrapper = {
  textAlign: "left",
  paddingTop: spacing.s,
};
const linkStyle = {
  cursor: "pointer",
};
const privacyWarningWrapper = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "end",
};
const afterPaymentContainer = css({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  background: colors.white,
  width: "100%",
  padding: spacing.l,
});
const buttonsWrapper = {
  display: "flex",
  gap: spacing.m,
  flexWrap: "wrap",
  width: "100%",
};
const fullWidth = {
  width: "100%",
};
const fieldsWrapper = css({
  marginBottom: spacing.m,
});
const postalCodeMask = createTextMask({
  pattern: "99999-999",
  guide: false,
  allowEmpty: false,
});

export default function PaymentMethodBankSlip(props) {
  const {
    submitSucceeded,
    externalContent,
    externalContentDetails,
    copyToClipboardHandler,
    street,
    neighborhood,
    cityId,
    stateId,
    stateIdField,
    addressLoading,
    states,
    cities,
    fetchCities,
    discountConfirmed,
    confirmDiscount,
    incompleteName,
    incompletePhone,
    user,
    resetBillingTypeHandler,
    handleSubmit,
    invalid,
    submitting,
    bankslipBarcodeUrl,
    paymentValue,
    error,
    countryCodeOptions,
  } = props;
  const submitHandler = useCallback(() => {
    !discountConfirmed && confirmDiscount();
    handleSubmit();
  }, [handleSubmit, confirmDiscount, discountConfirmed]);

  return submitSucceeded ? (
    <div className={afterPaymentContainer}>
      <div style={titleWrapper}>
        <h3 className={titleClass}>Boleto</h3>

        <p>
          Para pagar pelo Internet Banking, copie a linha digitável ou escaneie
          o código de barras. Para pagar em qualquer banco, caixa eletrônico ou
          lotérica, por favor, imprima o boleto.
        </p>
      </div>

      <div style={afterPaymentWrapper}>
        <img src={bankslipBarcodeUrl} height="100px" width="auto" />

        <div style={copyWrapper}>
          <div style={codeWrapper}>{externalContentDetails}</div>

          <Link onClick={copyToClipboardHandler} style={copyButton}>
            Copiar
          </Link>
        </div>

        <div style={fullWidth}>
          <p style={fullWidth}>
            <b>Recebedor:</b> T2 Educação S/A
          </p>
          <p style={fullWidth}>
            <b>Valor:</b> {toBRLCurrency(paymentValue)}
          </p>
        </div>

        <div style={buttonsWrapper}>
          <Button
            type="button"
            onClick={copyToClipboardHandler}
            content="Copiar linha digitável"
            color="black"
            size="big"
            fluid
          />
          <Link
            type="button"
            href={externalContent}
            target="_blank"
            component={Button}
            content="Imprimir Boleto"
            basic
            color="black"
            size="big"
            fluid
          />
        </div>

        <p style={textStyle}>
          {`Se o pagamento é feito de segunda a sexta, é creditado no dia seguinte. 
            Se você paga no fim de semana, será creditado na terça-feira.`}
        </p>
      </div>
    </div>
  ) : (
    <div className={container}>
      <div style={titleWrapper}>
        <h3 className={titleClass}>Boleto</h3>

        {error && <Message content={error} error={error} />}
      </div>

      <div className={fieldsWrapper}>
        {incompleteName && (
          <NameField
            name="customerData[name]"
            placeholder="Nome Completo"
            fluid
            required
          />
        )}
        {incompletePhone && (
          <PhoneField
            name="customerData[phone]"
            countryCodeOptions={countryCodeOptions}
            countryCodeFieldName="customerData[phoneCountryCodeId]"
            required
            fluid
          />
        )}
        <DocumentField
          name="customerData[cpfCnpj]"
          placeholder="CPF / CNPJ"
          fluid
          required
        />
      </div>

      <AddressFields
        street={street}
        neighborhood={neighborhood}
        cityId={cityId}
        stateId={stateId}
        stateIdField={stateIdField}
        addressLoading={addressLoading}
        states={states}
        cities={cities}
        fetchCities={fetchCities}
      />

      <Button
        type={discountConfirmed ? "submit" : "button"}
        color="black"
        icon="shopping cart"
        content="GERAR BOLETO"
        size="large"
        fluid
        disabled={invalid || submitting}
        onClick={submitHandler}
        confirmationText={
          discountConfirmed
            ? undefined
            : "Você tem direito ao desconto de aluno da T2!"
        }
        confirmButton="Usar desconto"
        cancelButton="Cancelar"
        style={buyButtonStyle}
      />

      <div style={backWrapper}>
        <Link style={linkStyle} onClick={resetBillingTypeHandler}>
          <Icon name="angle left" /> Etapa Anterior
        </Link>
      </div>

      <div style={privacyWarningWrapper}>
        <CourseCheckoutPrivacyWarning />
      </div>
    </div>
  );
}
