import React from "react";
import { css } from "glamor";
import { colors, spacing } from "../library/theme";
import { Icon, Modal } from "semantic-ui-react";
import { makeIsMobile } from "../../utils/useScreenWidth";
import Button from "../library/Button";
import LessonRatingFormContainer from "./LessonRatingFormContainer";

const modalClass = css({
  maxWidth: "375px",
  padding: spacing.m,
  ".ui.modal>.close": {
    color: colors.greyShadow,
    top: spacing.xs,
    right: spacing.xs,
  },
});
const modalTitle = {
  color: colors.greyShadow,
  fontWeight: "normal",
};
const watchedButton = css({
  textTransform: "uppercase !important",
  borderRadius: "25px !important",
  backgroundColor: "auto",
});
const unwatchedStyle = {
  backgroundColor: `${colors.secondary} !important`,
  color: colors.greyShadow,
};

export default function LessonEnding({
  canRate,
  watchedButtonEnabled,
  markAsWatched,
  watched,
  openModal,
  toggleModal,
  endingHandler,
  lessonsUser,
}) {
  const isMobile = makeIsMobile();

  return (
    <>
      {canRate && openModal && (
        <Modal open closeIcon onClose={toggleModal} className={modalClass}>
          <h2 style={modalTitle}>Concluir Aula</h2>
          <LessonRatingFormContainer closeHandler={endingHandler} />
        </Modal>
      )}
      {watchedButtonEnabled && (
        <Button
          onClick={canRate ? toggleModal : markAsWatched}
          disabled={watched}
          className={css(watchedButton, !watched && unwatchedStyle).toString()}
          size={isMobile ? "small" : "large"}
        >
          {watched && <Icon name="check" style={unwatchedStyle} />}
          {watched ? "Aula Concluída" : "Concluir Aula"}
        </Button>
      )}
    </>
  );
}
