import { css } from "glamor";
import React, { useState, useCallback } from "react";
import { BiCog, BiHome } from "react-icons/bi";
import { IoDocumentTextOutline } from "react-icons/io5";
import { PiGraduationCap } from "react-icons/pi";
import { useSelector } from "react-redux";
import { matchPath, useLocation } from "react-router";
import {
  hasMockCourses as hasMockCoursesSelector,
  canAccessMockList,
} from "../../selectors/courseSelectors";
import { getCurrentUser } from "../../selectors/userSelectors";
import { makeIsMobile } from "../../utils/useScreenWidth";
import Link from "../library/Link";
import Icon from "../library/Icon";
import Popup from "../library/Popup";
import { colors, spacing, typographyColors, styles } from "../library/theme";
import LogoSmall from "../library/images/logo-small.svg";
import Logo from "../library/images/logo.svg";
import { getAllClassrooms } from "../../selectors/classroomSelectors";
import { MdGroups } from "react-icons/md";
import { getAllCompanies } from "../../selectors/companySelectors";
import { RiBankFill } from "react-icons/ri";
import Spinner from "../library/Spinner";
import MyPerformanceSidebarItem from "./MyPerformanceSidebarItem";


const SIDEBAR_WIDTH = "250px";
const SMALL_SIDEBAR_WIDTH = "100px";
const SIDEBAR_EXPANDED = "sidebarExpanded";

const container = css({
  display: "flex",
  flexDirection: "column",
  backgroundColor: colors.white,
});
const sidebarContent = {
  position: "fixed",
  backgroundColor: colors.white,
  borderRight: "1px solid #E2F0F5",
};
const topContent = css({
  display: "flex",
  alignItems: "center",
  padding: `${spacing.l} 0px ${spacing.xl} 0px`,
});
const logoWrapperStyle = { flex: 1 };
const logoClass = css({
  flex: 1,
  margin: `0 ${spacing.m}`,
  display: "flex",
  alignItems: "auto",
});
const expandedLogoClass = css({
  paddingLeft: spacing.s,
});
const content = css({
  flex: 1,
  minWidth: SMALL_SIDEBAR_WIDTH,
  maxWidth: SMALL_SIDEBAR_WIDTH,
  minHeight: "100vh",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: `0px ${spacing.m}`,
});
const expandedContent = css({
  minWidth: SIDEBAR_WIDTH,
  maxWidth: SIDEBAR_WIDTH,
});
const expandTriggerClass = css({
  cursor: "pointer",
  backgroundColor: colors.primary,
  height: 42,
  width: 42,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "> i.icon": { height: "auto", margin: "auto" },
  "@media(max-width: 768px)": {
    display: "none",
  },
});
const expandTriggerIndicatorStyle = {
  color: colors.white,
  fontSize: 20,
};
export const sidebarItemClass = css(styles.alternativeFontFamily, {
  fontSize: 15,
  height: 42,
  color: `${typographyColors.defaultTitle} !important`,
  width: "100%",
  borderRadius: spacing.s,
  padding: `${spacing.xs} ${spacing.s}`,
  margin: `0px ${spacing.l} ${spacing.xxs} ${spacing.l}`,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: spacing.xs,
  ":hover": {
    backgroundColor: "#EEF2FB !important",
  },
  " > svg": {
    color: `${colors.primary} !important`,
  },
});
export const activeItemClass = css({ backgroundColor: "#EEF2FB !important" });
export const disabledColorClass = css({
  color: `${colors.grey} !important`,
  "> svg": {
    color: "inherit !important",
  },
});
const disabledItemClass = css(disabledColorClass, {
  pointerEvents: "none",
});
export const expandedItemClass = css({
  justifyContent: "start !important",
});
const separatorClass = css({
  width: "100%",
  margin: spacing.m,
  borderTop: `1px solid #E2F0F5`,
});
const sidebarItemLabelClass = css({
  marginTop: spacing.xxs,
});

const SidebarItem = (props) => {
  const { to, href, active, label, target, icon, disabled, isExpanded } = props;

  return (
    <Popup
      content={label}
      disabled={isExpanded}
      position="right center"
      trigger={
        <Link
          to={to}
          href={href}
          target={target}
          className={css(
            sidebarItemClass,
            disabled && disabledItemClass,
            isExpanded && expandedItemClass,
            active && activeItemClass
          )}
        >
          {icon}{" "}
          {isExpanded ? (
            <span className={sidebarItemLabelClass}>{label}</span>
          ) : null}
        </Link>
      }
    />
  );
};

export default function AppSidebar({}) {
  const [expanded, setExpanded] = useState(
    localStorage.getItem(SIDEBAR_EXPANDED) === "true"
  );
  const toggleExpanded = useCallback(() => {
    localStorage.setItem(SIDEBAR_EXPANDED, !expanded);
    setExpanded(!expanded);
  }, [expanded]);

  const { pathname } = useLocation();
  const isMobile = makeIsMobile();
  const isExpanded = expanded || isMobile;

  const user = useSelector((state) => getCurrentUser(state));
  const baseUrl = process.env.API_URL;
  const hasMockCourses = useSelector((state) => hasMockCoursesSelector(state));
  const mockListEnabled = useSelector((state) => canAccessMockList(state));
  const classrooms = useSelector((state) => getAllClassrooms(state));
  const companies = useSelector((state) => getAllCompanies(state));

  return (
    <div className={css(container, { width: isExpanded ? 250 : 100 })}>
      <span style={sidebarContent}>
        <div className={topContent}>
          <Link to="/" style={logoWrapperStyle}>
            <img
              src={isExpanded ? Logo : LogoSmall}
              className={css(logoClass, isExpanded && expandedLogoClass)}
              width={isExpanded ? 83 : 19}
              height={isExpanded && 24}
            />
          </Link>

          <Popup
            content={expanded ? "Recolher menu" : "Expandir menu"}
            position="right center"
            trigger={
              <div className={expandTriggerClass} onClick={toggleExpanded}>
                <Icon
                  name={`angle ${isExpanded ? "left" : "right"}`}
                  style={expandTriggerIndicatorStyle}
                />
              </div>
            }
          />
        </div>
        <div className={css(content, isExpanded && expandedContent)}>
          <SidebarItem
            to="/"
            active={!!matchPath(pathname, { path: "/", exact: true })}
            label="Página Inicial"
            icon={<BiHome size={20} />}
            isExpanded={isExpanded}
          />
          <SidebarItem
            to="/my-courses"
            active={!!matchPath(pathname, { path: "/my-courses", exact: true })}
            label="Meus Cursos"
            icon={<PiGraduationCap size={20} />}
            isExpanded={isExpanded}
          />
          <SidebarItem
            to="/mocks"
            active={!!matchPath(pathname, { path: "/mocks" })}
            label="Simulados"
            disabled={!mockListEnabled}
            icon={<IoDocumentTextOutline size={20} />}
            isExpanded={isExpanded}
          />
          <MyPerformanceSidebarItem
            hasMockCourses={hasMockCourses}
            isExpanded={isExpanded}
            pathname={pathname}
            isMobile={isMobile}
          />

          {!user?.isStudent && (
            <>
              <div className={separatorClass} />
              {user?.meta?.features?.canManageCourses && (
                <SidebarItem
                  href={`${baseUrl}/manager`}
                  target="_blank"
                  label="Manager"
                  icon={<BiCog size={20} />}
                  isExpanded={isExpanded}
                />
              )}

              {!!classrooms?.length && (
                <SidebarItem
                  to="/classrooms"
                  active={!!matchPath(pathname, { path: "/classrooms" })}
                  label="Minhas turmas"
                  icon={<MdGroups size={20} />}
                  isExpanded={isExpanded}
                />
              )}

              {companies.map(({ id, name }) => (
                <SidebarItem
                  to={`/companies/${id}`}
                  active={!!matchPath(pathname, { path: `/companies/${id}` })}
                  label={name}
                  icon={<RiBankFill size={20} />}
                  isExpanded={isExpanded}
                />
              ))}
            </>
          )}
        </div>
      </span>
    </div>
  );
}
