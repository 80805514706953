import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { fetchAnnotations } from "../../processes/annotationProcesses";
import { fetchLessonFiles } from "../../processes/lessonFileProcesses";
import { createMock } from "../../processes/mockProccess";
import { getAnnotationsByLesson } from "../../selectors/annotationSelectors";
import {
  currentCourse,
  getCurrentCourseId,
  makeCalculatorButtonEnabled,
  canAccessCourse,
} from "../../selectors/courseSelectors";
import { makeHasFiles } from "../../selectors/lessonFileSelectors";
import {
  getCurrentLesson,
  getCurrentLessonId,
  makeIsCommentsEnabled,
} from "../../selectors/lessonSelectors";
import { canRateLesson } from "../../selectors/ratingSelectors";
import LessonActions from "./LessonActions";

function LessonActionsContainer(props) {
  const { dispatch, course, lesson, currentCourseId } = props;
  const [loadingMock, setLoadingMock] = useState(false);
  const [openCalculator, setOpenCalculator] = useState(false);
  const [activeTab, setActiveTab] = useState("content");
  const activeTabHandler = useCallback(
    (tab) => {
      setActiveTab(tab);
    },
    [setActiveTab]
  );
  const toogleCalculator = useCallback(
    () => setOpenCalculator(!openCalculator),
    [openCalculator]
  );

  useEffect(() => {
    fetchLessonFiles(dispatch, currentCourseId, lesson?.id);
  }, [dispatch, currentCourseId, lesson?.id]);

  const createMockHandler = useCallback(() => {
    setLoadingMock(true);
    createMock(
      { mockTemplateId: lesson?.mockTemplateId, exam: true },
      dispatch
    ).then(() => {
      setLoadingMock(false);
    });
  }, [dispatch, lesson?.mockTemplateId]);

  useEffect(() => {
    if (course?.id && lesson?.id) {
      fetchAnnotations(dispatch, course?.id, lesson?.id);
    }
  }, [dispatch, course?.id, lesson?.id]);

  return (
    <LessonActions
      createMockHandler={createMockHandler}
      loadingMock={loadingMock}
      activeTab={activeTab}
      activeTabHandler={activeTabHandler}
      openCalculator={openCalculator}
      toogleCalculator={toogleCalculator}
      {...props}
    />
  );
}

function mapStateToProps(state) {
  const currentLessonId = getCurrentLessonId(state);
  const lesson = getCurrentLesson(state, currentLessonId);

  return {
    lesson,
    hasFiles: makeHasFiles(state, lesson?.id),
    currentCourseId: getCurrentCourseId(state),
    course: currentCourse(state),
    calculatorEnabled: makeCalculatorButtonEnabled(state),
    commentsEnabled: makeIsCommentsEnabled(state),
    annotations: getAnnotationsByLesson(state, lesson?.id),
    canRate: canRateLesson(state, currentLessonId),
    canAccessCourse: canAccessCourse(state, lesson?.courseId),
  };
}

export default connect(mapStateToProps)(LessonActionsContainer);
