import { matchPath } from "react-router-dom";
import { createSelector } from "reselect";
import {
  getAllCoursesUsersFromCurrentUser,
  getCourseUserByCourseId,
  getCurrentCourseUser,
  getCurrentUserTrialCoursesUsers,
} from "./coursesUserSelectors";
import { hasCourse } from "./helpers/hasCourse";
import orderByPosition from "./helpers/orderByPosition";
import { getMock } from "./mockSelectors";
import { getCurrentCourseGrade } from "./reportSelectors";
import { getCurrentTrail } from "./trailSelectors";
import { getCurrentUser } from "./userSelectors";

export const getCourses = (state) => state.entities.courses;
export const getCurrentCourseId = (state) => state.currentCourseId;
export const getCourseId = (_, id) => id;
export const getFetchedCourseIds = (state) => state.coursesList.courseIds;
export const getCertificateCourses = (state) =>
  state.certificates.certificateCourses;
const getProps = (_, props) => props;
const getPathname = (state) => state.router.location.pathname;

const orderByProgress = (a, b) => {
  return b?.meta?.progress - a?.meta?.progress;
};

export const getAllCourses = createSelector(getCourses, (courses) =>
  Object.values(courses)
);

export const getUserCourses = createSelector(
  getCourses,
  getAllCoursesUsersFromCurrentUser,
  getCurrentUser,
  (courses, coursesUsers = [], currentUser) => {
    if (currentUser?.meta?.features?.canManageCourses) {
      return Object.values(courses).sort(orderByPosition);
    }
    return coursesUsers
      .map(({ courseId }) => courses[courseId])
      .filter((item) => !!item)
      .sort(orderByPosition);
  }
);

export const getAllValidCourses = createSelector(
  getCourses,
  getAllCoursesUsersFromCurrentUser,
  getCurrentUser,
  (courses, coursesUsers = [], currentUser) => {
    if (currentUser?.meta?.features?.canManageCourses) {
      return Object.values(courses).sort(orderByPosition);
    }
    return coursesUsers
      .filter(({ expired, status }) => !expired && status === "enabled")
      .map(({ courseId }) => courses[courseId])
      .filter((item) => !!item)
      .sort(orderByPosition);
  }
);

export const getUserCoursesForDropdownOptions = createSelector(
  getAllValidCourses,
  (courses) => courses.map(({ id, name }) => ({ id, name }))
);

export const hasMockCourses = createSelector(getAllValidCourses, (courses) =>
  courses?.some(({ mocksEnabled }) => mocksEnabled)
);

export const hasMocks = createSelector(getAllCourses, (courses) =>
  courses.some((course) => course?.meta?.mocksCount > 0)
);

export const canAccessMockList = createSelector(
  hasMockCourses,
  hasMocks,
  (hasMockCourses, hasMocks) => hasMocks || hasMockCourses
);

export const getCourseById = createSelector(
  getCourses,
  getCourseId,
  (courses, id) => courses[id]
);

export const canAccessCourse = createSelector(
  getCourseUserByCourseId,
  getCurrentUser,
  (courseUser, currentUser) => {
    if (currentUser?.meta?.features?.canManageCourses) return true;

    if (
      !!courseUser &&
      courseUser?.status === "enabled" &&
      !courseUser?.expired
    )
      return true;

    return false;
  }
);

export const getUnenrolledCourses = createSelector(
  getAllCourses,
  getAllValidCourses,
  (courses, userCourses) => {
    const courseIds = userCourses?.map((course) => course.id);

    return courses.filter((course) => !courseIds?.includes(course.id));
  }
);

export const currentCourse = createSelector(
  getCourses,
  getCurrentCourseId,
  (courses, id) => courses[id]
);

export const getCourseFromMock = createSelector(
  getCourses,
  getMock,
  (courses, mock) => courses[mock?.courseId]
);

export const getMockCourses = createSelector(getAllValidCourses, (courses) =>
  courses
    .filter(({ mocksEnabled }) => mocksEnabled)
    .map(({ description, ...attrs }) => attrs)
);

export const getCourseForDropdownOptions = createSelector(
  getMockCourses,
  (courses) => courses.map(({ description, ...attrs }) => ({ ...attrs }))
);

export const coursesForMenuOptions = createSelector(
  getMockCourses,
  (courses) => {
    const menuOptionsCourses = courses.map(({ id, slug }) => ({
      id,
      slug: slug.toUpperCase(),
    }));
    menuOptionsCourses.unshift({ slug: "Todos" });

    return menuOptionsCourses;
  }
);

export const getCourseIdsByMocksPresence = createSelector(
  getMockCourses,
  getCurrentUserTrialCoursesUsers,
  (courses, coursesUsers) => {
    return courses
      .filter((course) => {
        const isTrial = coursesUsers
          ?.map(({ courseId }) => courseId)
          ?.includes(course.id);
        const hasMocks = course?.meta?.mocksCount > 0;

        return isTrial && hasMocks;
      })
      .map(({ id }) => id);
  }
);

export const makeWatchedButtonEnabled = createSelector(
  currentCourse,
  (course) => course?.watchedButtonEnabled
);

export const makeCalculatorButtonEnabled = createSelector(
  currentCourse,
  (course) => course?.calculatorEnabled
);

export const getCourseListByParams = createSelector(
  getAllValidCourses,
  getProps,
  getAllCourses,
  getCurrentUser,
  (userCourses, props, allCourses, currentUser) => {
    const { slug, justOwned, search } = props;

    const courses = justOwned ? userCourses : allCourses;

    return courses
      .filter((course) => {
        const byCategory = course.categories.includes(slug);

        const regex = new RegExp(search, "i");
        const searchMatch = regex.test(course.name);
        const bySearch = search ? searchMatch : true;

        const byAvailability =
          hasCourse(course, userCourses, currentUser) || course?.visible;

        return byCategory && bySearch && byAvailability;
      })
      .sort((a, b) => {
        const aHasCourse = hasCourse(a, userCourses, currentUser);
        const bHasCourse = hasCourse(b, userCourses, currentUser);

        if (aHasCourse !== bHasCourse) return aHasCourse ? -1 : 1;

        return a.position - b.position;
      });
  }
);

export const getCurrentTrailCourses = createSelector(
  getCourses,
  getCurrentTrail,
  (courses, trail) =>
    trail?.courseIds
      .map((id) => {
        return { ...courses[id], position: trail.courseIds.indexOf(id) };
      })
      .filter(({ id }) => !!id)
      .sort(orderByPosition)
);

export const getCoursePosition = createSelector(
  getCurrentTrail,
  getCourseId,
  (trail, courseId) => trail?.courseIds.indexOf(courseId) + 1
);

export const certificateCourses = createSelector(
  getCourses,
  getCertificateCourses,
  (courses, certificateCourses) => {
    const certificateCoursesIds =
      certificateCourses?.map((course) => course.id) || [];

    return certificateCoursesIds
      .map((id) => courses[id])
      .filter((item) => !!item)
      .sort(orderByPosition)
      .sort(orderByProgress);
  }
);

export const getAllTrialCourses = createSelector(
  getCourses,
  getCurrentUserTrialCoursesUsers,
  (courses, coursesUsers = []) => {
    return coursesUsers
      .map(({ courseId }) => courses[courseId])
      .filter((item) => !!item)
      .sort(orderByPosition);
  }
);

export const getTrialCourseForCountdown = createSelector(
  getAllTrialCourses,
  getPathname,
  currentCourse,
  getCurrentCourseUser,
  (courses, pathname, currentCourse, courseUser) => {
    const isCurrentCoursePath = !!matchPath(pathname, {
      path: `/courses/${currentCourse?.id}`,
    });
    const isCheckoutPath = !!matchPath(pathname, {
      path: `/courses/:courseId/checkout`,
      exact: true,
    });
    const hasOneTrial = courses.length == 1;
    const hasMultipleTrials = courses.length > 1;
    const isCoursePath = !!matchPath(pathname, {
      path: "/courses/",
    });

    if (isCheckoutPath) return undefined;

    if (hasOneTrial) {
      if (isCoursePath && currentCourse?.id != courses[0]?.id) return undefined;

      return courses[0];
    }

    if (hasMultipleTrials && isCurrentCoursePath && courseUser?.trial) {
      return currentCourse;
    }

    return undefined;
  }
);

export const canAccessTrialEnrollment = createSelector(
  canAccessCourse,
  getCourseUserByCourseId,
  (canAccessEnrollment, enrollment) => canAccessEnrollment && enrollment?.trial
);

export const canUpdateIntercomAttributes = createSelector(
  getUserCourses,
  getAllValidCourses,
  currentCourse,
  getCurrentCourseGrade,
  canAccessCourse,
  (
    courses,
    notExpiredCourses,
    currentCourse,
    currentCourseGrade,
    hasCurrentCourse
  ) => {
    return (
      !!currentCourse &&
      !!currentCourseGrade &&
      hasCurrentCourse &&
      currentCourse?.meta?.progress != undefined &&
      notExpiredCourses?.length != undefined &&
      courses?.length != undefined
    );
  }
);

export const getIntercomAttributes = createSelector(
  getUserCourses,
  getAllValidCourses,
  currentCourse,
  getCurrentCourseGrade,
  getCurrentCourseUser,
  canAccessCourse,
  (courses, validCourses, currentCourse, currentCourseGrade, courseUser) => {
    return {
      Cursos_Cadastrados: courses?.map((course) => course.name)?.join(", "),
      Cursos_Matriculados: validCourses
        ?.map((course) => course.name)
        ?.join(", "),
      Curso_Atual: currentCourse?.name,
      Curso_Atual_Trial: courseUser?.trial ? "Sim" : "Não",
      Progresso_Curso_Atual: currentCourse?.meta?.progress,
      Nota_Geral_Do_Curso_Atual: currentCourseGrade,
    };
  }
);
