import { css } from "glamor";
import React, { useCallback } from "react";
import Link from "./Link";
import { getCurrentUser } from "../../selectors/userSelectors";
import { isString } from "../../utils/isString";
import { makeIsMobile } from "../../utils/useScreenWidth";
import Footer from "../footer/Footer";
import Logo from "../library/images/logo.svg";
import SidebarContainer from "../sidebar/SidebarContainer";
import UserSidebarContainer from "../sidebar/UserSidebarContainer";
import NotificationsContainer from "../notifications/NotificationsContainer";
import { colors, spacing, styles } from "./theme";
import CourseTrialCountdownContainer from "../../components/courses/CourseTrialCountdownContainer";
import CourseUnlockContainer from "../../components/courses/CourseUnlockContainer";
import { TbLogout } from "react-icons/tb";
import { signOut } from "../../processes/sessionProcesses";
import { useDispatch, useSelector } from "react-redux";

const LOGO_SIZE = 100;

const container = css({
  display: "flex",
});
const pageContent = css({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  padding: `${spacing.xs} ${spacing.l} 0px ${spacing.l}`,
  maxWidth: "1300px",
  margin: "0 auto",
  minHeight: "100vh",
  overflowX: "auto",
  "@media(max-width: 768px)": {
    overflowX: "visible",
  },
});
const header = css({
  display: "flex",
  alignItems: "center",
  marginBottom: spacing.l,
  gap: spacing.s,
  paddingBottom: spacing.xs,
  borderBottom: "3px solid #F2F2F2",
  justifyContent: "flex-end",
  "@media(max-width: 768px)": {
    justifyContent: "space-between",
    paddingBottom: spacing.s,
  },
});

const logoClass = css({
  width: LOGO_SIZE,
  marginLeft: spacing.m,
});
const content = css({
  display: "flex",
  flexDirection: "column",
  flex: 1,
  marginBottom: spacing.l,
});
const titleWrapper = css({
  display: "flex",
  justifyContent: "space-between",
  flexWrap: "wrap",
  alignItems: "center",
  gap: spacing.m,
  marginBottom: spacing.l,
});
const titleWithIcon = css({
  display: "flex",
  alignItems: "center",
  gap: spacing.m,
});
const titleClass = css(styles.alternativeFontFamily, {
  display: "flex",
  alignItems: "center",
  gap: spacing.s,
  fontSize: 24,
  fontWeight: "bold",
  color: colors.secondary,
  lineHeight: 1.5,
});
const actionWrapperClass = css(styles.boxShadow, {
  cursor: "pointer",
  height: 44,
  width: 44,
  borderRadius: "50%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  ":hover": {
    backgroundColor: "#EEF2FB",
    color: colors.primary,
  },
});
const notificationWrapper = css({
  display: "flex",
  alignItems: "center",
});

const lobbyPageStyle = {
  padding: `${spacing.xxl} ${spacing.m}`,
};

const iconStyle = { color: colors.black };

export default function Page({ title, icon, children }) {
  const isMobile = makeIsMobile();
  const dispatch = useDispatch();
  const signOutHandler = useCallback(() => signOut(dispatch), [dispatch]);

  const user = useSelector((state) => getCurrentUser(state));

  const Icon = icon;

  return (
    <div className={container}>
      {!isMobile && <SidebarContainer />}

      <div className={css(pageContent, !user && lobbyPageStyle)}>
        {user && (
          <>
            {isMobile ? (
              <>
                <div className={header}>
                  <SidebarContainer />

                  <Link to="/">
                    <img src={Logo} className={logoClass} />
                  </Link>
                  <div className={notificationWrapper}>
                    <NotificationsContainer />
                    <UserSidebarContainer />
                  </div>
                </div>

                <CourseUnlockContainer />
                <CourseTrialCountdownContainer />
              </>
            ) : (
              <div className={header}>
                <CourseUnlockContainer />
                <CourseTrialCountdownContainer />

                <div style={{ display: "flex", alignItems: "center" }}>
                  <UserSidebarContainer />
                  <NotificationsContainer />
                  <div className={actionWrapperClass} onClick={signOutHandler}>
                    <TbLogout size={25} />
                  </div>
                </div>
              </div>
            )}
          </>
        )}

        <div className={titleWrapper}>
          <div className={titleWithIcon}>
            {icon && <Icon style={iconStyle} size={30} />}{" "}
            {isString(title) ? (
              <span className={titleClass}>{title}</span>
            ) : (
              title
            )}
          </div>
        </div>

        <div className={content}>{children}</div>

        {user && <Footer />}
      </div>
    </div>
  );
}
