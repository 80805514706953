import React from "react";
import { toUpperCase } from "../../utils/toUpperCase";
import { Button, Link, colors, spacing, styles } from "../library";
import { Form, TextField } from "../library/forms";

const formStyle = {
  ...styles.shadow,
  padding: spacing.s,
  background: colors.white,
  borderRadius: spacing.s,
};
const formWrapperStyle = {
  marginTop: spacing.xxl,
  marginBottom: spacing.m,
};
const submitWrapper = {
  display: "flex",
  justifyContent: "center",
  marginTop: spacing.s,
};
const openCouponStyle = {
  display: "block",
  textDecoration: "underline",
  cursor: "pointer",
  margin: `${spacing.s} 0px ${spacing.m} 0px`,
};

export default function SearchCouponForm(props) {
  const {
    showCouponForm,
    openShowCouponForm,
    submitting,
    hasCoupon,
    submitSucceeded,
  } = props;

  return submitSucceeded && hasCoupon ? null : (
    <>
      {showCouponForm ? (
        <Form
          onSubmit={props.handleSubmit}
          error={props.error}
          style={formStyle}
          wrapperStyle={formWrapperStyle}
        >
          <TextField
            name="code"
            label="Insira o cupom de desconto"
            placeholder="Digite o seu cupom aqui"
            onInput={toUpperCase}
            fluid
          />

          <div style={submitWrapper}>
            <Button
              type="submit"
              color="green"
              loading={submitting}
              disabled={submitting || hasCoupon}
            >
              Validar Cupom
            </Button>
          </div>
        </Form>
      ) : (
        <Link onClick={openShowCouponForm} style={openCouponStyle}>
          Adicionar cupom de desconto
        </Link>
      )}
    </>
  );
}
