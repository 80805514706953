import { css } from "glamor";
import React from "react";
import { FiDownload } from "react-icons/fi";
import { IoDocumentText } from "react-icons/io5";
import { RiPushpinFill } from "react-icons/ri";
import { Icon, Menu, Tab } from "semantic-ui-react";
import AnnotationListItemContainer from "../annotations/AnnotationListItemContainer";
import AnnotationsFormContainer from "../annotations/AnnotationsFormContainer";
import LessonFilesContainer from "../lessonFiles/LessonFilesContainer";
import Button from "../library/Button";
import Link from "../library/Link";
import Spinner from "../library/Spinner";
import { colors, spacing, styles, uiColors } from "../library/theme";

const buttonActions = {
  backgroundColor: colors.greyClear,
  borderBottomLeftRadius: spacing.xs,
  borderBottomRightRadius: spacing.xs,
  marginTop: spacing.s,
};
const actionsClass = css({
  display: "flex",
  alignItems: "start",
  gap: spacing.s,
  flexFlow: "wrap",
  padding: spacing.m,
  "@media(max-width: 575px)": { justifyContent: "center" },
});
const calculatorWrapper = css({
  margin: `${spacing.m} auto`,
  height: "380px",
  width: "600px",
  overflow: "hidden",
});
const iframeClass = css({
  position: "relative",
  top: -220,
  left: -100,
});
const buttonLoader = {
  marginRight: spacing.s,
};
const examCTAWrapper = css({
  borderTop: `1px solid ${uiColors.border}`,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: spacing.m,
});
const htmlClass = css({
  ...styles.wrappingText,
  whiteSpace: "pre-line",
  paddingTop: spacing.m,
  "& + &": {
    marginTop: spacing.m,
  },
});
const annotationContainer = css({
  display: "flex",
  gap: spacing.m,
  flexWrap: "wrap",
  justifyContent: "center",
  alignItems: "flex-start",
});
const tabWrapper = css({
  "& .ui.tabular.menu": {
    overflowX: "auto",
    scrollbarWidth: "none",
    "::-webkit-scrollbar": {
      display: "none",
    },
  },
  "& .ui.tabular.menu .item": {
    fontWeight: "600",
    color: colors.greyShadow,
    border: "none",
    borderBottom: "4px solid transparent",
    "> svg": {
      color: colors.primaryShadow,
      marginRight: spacing.xs,
    },
  },
  "& .ui.tabular.menu .active.item": {
    background: "transparent",
    margin: 0,
    border: "none",
    padding: spacing.m,
    borderBottom: `4px solid ${colors.primaryLighter}`,
  },
  "& .ui.tab.active, .ui.tab.open": {
    marginTop: spacing.m,
    padding: 0,
    background: "transparent !important",
    border: "none",
    boxShadow: "none",
  },
});

const makeLessonTabPanes = ({
  lesson,
  annotations,
  canAccessCourse,
  hasFiles,
}) => {
  let tabPanes = [
    {
      menuItem: (
        <Menu.Item key="content">
          <IoDocumentText size={22} />
          Descrição
        </Menu.Item>
      ),
      render: () => (
        <Tab.Pane attached={false}>
          <div>
            {lesson.description && (
              <div
                className={htmlClass}
                dangerouslySetInnerHTML={{
                  __html: lesson.description,
                }}
              ></div>
            )}
            {lesson.thirdPartyContent && (
              <div
                className={htmlClass}
                dangerouslySetInnerHTML={{
                  __html: lesson.thirdPartyContent,
                }}
              ></div>
            )}
          </div>
        </Tab.Pane>
      ),
    },
  ];

  if (hasFiles) {
    tabPanes.push({
      menuItem: (
        <Menu.Item key="downloads">
          <FiDownload size={22} /> Downloads
        </Menu.Item>
      ),
      render: () => (
        <Tab.Pane attached={false}>
          <div>
            <LessonFilesContainer lessonId={lesson?.id} />
          </div>
        </Tab.Pane>
      ),
    });
  }

  if (canAccessCourse) {
    tabPanes.push({
      menuItem: (
        <Menu.Item key="annotations">
          <RiPushpinFill size={22} /> Anotações
        </Menu.Item>
      ),
      render: () => (
        <Tab.Pane attached={false}>
          <div className={annotationContainer}>
            <AnnotationsFormContainer lessonId={lesson?.id} />
            {annotations.map((annotation, index) => (
              <AnnotationListItemContainer
                key={index}
                annotation={annotation}
              />
            ))}
          </div>
        </Tab.Pane>
      ),
    });
  }

  return tabPanes;
};

export default function LessonActions(props) {
  const {
    calculatorEnabled,
    currentCourseId,
    lesson,
    course,
    createMockHandler,
    loadingMock,
    openCalculator,
    toogleCalculator,
    annotations,
    canAccessCourse,
    hasFiles,
  } = props;
  const calculatorVisibility = { display: openCalculator ? "block" : "none" };

  return (
    <>
      <div style={buttonActions}>
        {calculatorEnabled && (
          <div className={actionsClass}>
            <Button onClick={toogleCalculator}>
              {openCalculator ? "Fechar Calculadora" : "Calculadora HP"}
            </Button>
          </div>
        )}

        {!!course && !!lesson?.mockTemplateId && (
          <div className={examCTAWrapper}>
            <Button
              color="green"
              onClick={createMockHandler}
              disabled={loadingMock}
            >
              {loadingMock && (
                <Spinner inline size="tiny" style={buttonLoader} />
              )}
              Prova {course?.name}
            </Button>
          </div>
        )}

        <div className={css(calculatorWrapper, calculatorVisibility)}>
          <iframe
            className={iframeClass}
            src="https://www.accountcontabilidade.com.br/hp12c/"
            frameBorder="0"
            border="0"
            width="800px"
            height="590px"
            scrolling="no"
          ></iframe>
        </div>
      </div>

      <div className={tabWrapper}>
        <Tab
          panes={makeLessonTabPanes({
            lesson,
            annotations,
            canAccessCourse,
            hasFiles,
          })}
        />
      </div>
    </>
  );
}
