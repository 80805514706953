import { connect } from "react-redux";
import buildForm from "../library/forms/buildForm";
import AnnotationsForm from "./AnnotationsForm";
import { createAnnotation } from "../../processes/annotationProcesses";
import { getCurrentCourseId } from "../../selectors/courseSelectors";

const form = buildForm("annotationForm");

function mapStateToProps(state) {
  return {
    initialValues: {},
    currentCourseId: getCurrentCourseId(state),
  };
}

export default connect(mapStateToProps)(
  form.connect({
    onSubmit: createAnnotation,
  })(AnnotationsForm)
);
