import { css } from "glamor";
import React from "react";
import BannersContainer from "../banners/BannersContainer";
import { Page, spacing, Spinner } from "../library";
import CourseList from "./CourseList";

const bannersWrapper = css({
  marginBottom: spacing.l,
});

export default function Courses({
  user,
  loading,
  courseCategories,
  hasBanners,
}) {
  return (
    <Page title={user && `Olá, ${user.name.split(" ")[0]}!`}>
      {hasBanners && (
        <div className={bannersWrapper}>
          <BannersContainer />
        </div>
      )}
      {loading ? (
        <Spinner wrapped />
      ) : (
        <>
          {courseCategories && (
            <CourseList courseCategories={courseCategories} />
          )}
        </>
      )}
    </Page>
  );
}
