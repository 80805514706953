import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import LessonEnding from "./LessonEnding";
import {
  getCurrentLesson,
  getCurrentLessonId,
} from "../../selectors/lessonSelectors";
import {
  getLessonsUser,
  getIsWatchedByLessonId,
} from "../../selectors/lessonsUserSelectors";
import { canRateLesson } from "../../selectors/ratingSelectors";
import {
  getCurrentCourseId,
  makeWatchedButtonEnabled,
} from "../../selectors/courseSelectors";
import { upsertLessonsUser } from "../../processes/lessonsUserProccess";

function LessonEndingContainer(props) {
  const { dispatch, lesson, currentCourseId, canRate, lessonsUser } = props;
  const [openModal, setOpenModal] = useState(false);

  const toggleModal = useCallback(() => {
    setOpenModal(!openModal);
  }, [openModal]);

  const endingHandler = useCallback(async () => {
    await upsertLessonsUser(dispatch, currentCourseId, lesson.id, 1);
    setOpenModal(false);
  }, [dispatch, lesson, currentCourseId]);

  useEffect(() => {
    //This 0.95 should be 1, but a fix in api need to be done to
    //do not allow duplicate lessons users
    if (lessonsUser?.progress > 0.95 && canRate) {
      setOpenModal(true);
    }
  }, [lesson?.id, lessonsUser?.progress, canRate]);

  if (!lesson) {
    return null;
  }

  return (
    <LessonEnding
      openModal={openModal}
      toggleModal={toggleModal}
      endingHandler={endingHandler}
      {...props}
    />
  );
}

function mapStateToProps(state) {
  const currentLessonId = getCurrentLessonId(state);
  const lesson = getCurrentLesson(state, currentLessonId);

  return {
    lesson,
    lessonsUser: getLessonsUser(state, currentLessonId),
    watched: getIsWatchedByLessonId(state, currentLessonId),
    canRate: canRateLesson(state, currentLessonId),
    watchedButtonEnabled: makeWatchedButtonEnabled(state),
    currentCourseId: getCurrentCourseId(state),
  };
}

export default connect(mapStateToProps)(LessonEndingContainer);
