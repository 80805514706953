import { css } from "glamor";
import React from "react";
import { AiFillCaretLeft } from "react-icons/ai";
import { colors, Link, Page, spacing, Spinner } from "../library";
import CourseAnnotationsLessonContainer from "./CourseAnnotationsLessonContainer";
import EmptyCourseAnnotations from "./EmptyCourseAnnotations";

const back = css({
  color: colors.primaryDarker,
  maxWidth: "1080px",
  margin: `0 auto ${spacing.m} auto`,
  display: "flex",
  alignItems: "center",
  gap: spacing.xxs,
  ":hover": {
    color: colors.primary,
    cursor: "pointer",
  },
});

export default function CourseAnnotations(props) {
  const { lessons, course, annotations, loading } = props;

  return (
    <Page title={course?.name ? `ANOTAÇÕES - ${course?.name}` : "ANOTAÇÕES"}>
      {loading ? (
        <Spinner wrapped />
      ) : (
        <div>
          <Link to={`/courses/${course?.id}`} className={back} component="div">
            <AiFillCaretLeft size={14} /> VOLTAR
          </Link>
          {!annotations.length && <EmptyCourseAnnotations />}
          {lessons.map((lesson) => (
            <CourseAnnotationsLessonContainer
              key={lesson?.id}
              lesson={lesson}
              course={course}
            />
          ))}
        </div>
      )}
    </Page>
  );
}
