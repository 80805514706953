import { connect } from "react-redux";
import { getAnnotationsByLesson } from "../../selectors/annotationSelectors";
import CourseAnnotationsLesson from "./CourseAnnotationsLesson";

function mapStateToProps(state, ownProps) {
  const { lesson } = ownProps;

  return {
    annotations: getAnnotationsByLesson(state, lesson?.id),
  };
}

export default connect(mapStateToProps)(CourseAnnotationsLesson);
