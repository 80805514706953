import { css } from "glamor";
import React from "react";
import { Modal } from "semantic-ui-react";
import Button from "../library/Button";
import { Form, TextAreaField } from "../library/forms";
import RatingField from "../library/forms/RatingField";
import { colors, spacing } from "../library/theme";

const container = {
  color: colors.greyShadow,
  display: "flex",
  flexDirection: "column",
  gap: spacing.m,
};
const buttonsWrapper = css({
  display: "flex",
  justifyContent: "right",
  gap: spacing.s,
});
const cancel = {
  backgroundColor: "transparent",
  border: `1px solid ${colors.primaryShadow}`,
  color: colors.greyShadow,
  borderColor: colors.secondary,
  textTransform: "uppercase",
};
const modalClass = css({
  maxWidth: "375px",
  padding: spacing.m,
  ".ui.modal>.close": {
    color: colors.greyShadow,
    top: spacing.xs,
    right: spacing.xs,
  },
});
const modalTitle = {
  color: colors.greyShadow,
  fontWeight: "normal",
};

export default function LessonRatingForm(props) {
  const { canRate, submitHandler, openModal, watched, toggleModal } = props;
  return canRate && watched ? (
    <Form>
      <Button circular secondary onClick={toggleModal}>
        AVALIAR
      </Button>
      {openModal ? (
        <Modal open closeIcon onClose={toggleModal} className={modalClass}>
          <h2 style={modalTitle}>Avaliar Aula</h2>
          <Form style={container}>
            <RatingField
              name="value"
              label="O que você achou desta aula? Dê uma nota de 1 a 5 estrelas sendo 1 a
          pior nota e 5 a melhor."
              required
            />
            <TextAreaField
              name="text"
              label="Comentário (Opcional)"
              placeholder="Escreva um comentário"
              rows={3}
            />
            <div className={buttonsWrapper}>
              <Button onClick={toggleModal} style={cancel}>
                Cancelar
              </Button>
              <Button onClick={submitHandler} secondary>
                AVALIAR
              </Button>
            </div>
          </Form>
        </Modal>
      ) : null}
    </Form>
  ) : null;
}
